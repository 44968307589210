<template functional>
	<div
		class="chip"
		:class="[data.staticClass, props.condensed ? 'condensed' : '']"
		:role="listeners && listeners.click ? 'button' : false"
		v-on="listeners"
	>
		<font-awesome-icon
			v-if="props.icon"
			class="chip-icon"
			:icon="props.icon"
		/>

		<div class="chip-text">
			<slot />
		</div>

		<slot name="trailing" />
	</div>
</template>
<script>
export default {
	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'Chip',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * Determine if a condensed chip should be displayed.
		 *
		 * @type {Boolean}
		 */
		condensed: {
			type: Boolean,
			default: false,
		},

		/**
		 * An optional font-awesome icon to display.
		 *
		 * @type {String}
		 */
		icon: {
			type: [Array, String],
			default: '',
		},
	},
}
</script>
