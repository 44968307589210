<template>
	<div class="inbox-item" :class="{ active }">
		<div class="inbox-item-section">
			<div
				v-if="isAudioOnly"
				class="audio-only-call"
				data-tooltip="Audio Only"
				data-position="right"
			>
				<font-awesome-icon fixed-width :icon="['fas', 'volume']" />
			</div>
			<router-link
				v-if="call.patient_name"
				:to="{ name: 'app.calls.show', params: { sid: call.sid } }"
				class="inbox-item-title"
			>
				<div
					v-if="isComplete"
					class="complete-call"
					role="button"
					data-tooltip="Complete"
					data-position="right"
					@click.stop="onChipStatusClick('complete')"
				>
					<font-awesome-icon fixed-width :icon="['fas', 'check']" />
				</div>
				<div
					v-else-if="isCallDismissed"
					class="dismissed-call"
					role="button"
					data-tooltip="Dismissed"
					data-position="right"
					@click.stop="onChipStatusClick('dismissed')"
				>
					<font-awesome-icon fixed-width :icon="['far', 'circle']" />
				</div>
				<div
					v-else
					class="new-call"
					role="button"
					data-tooltip="New"
					data-position="right"
					@click.stop="onChipStatusClick('incomplete')"
				>
					<font-awesome-icon fixed-width :icon="['fas', 'circle']" />
				</div>

				{{ call.patient_name }}
			</router-link>

			<router-link
				v-else
				:to="{ name: 'app.calls.show', params: { sid: call.sid } }"
				class="inbox-item-title"
			>
				<div
					v-if="isComplete"
					class="complete-call"
					role="button"
					data-tooltip="Complete"
					data-position="right"
					@click.stop="onChipStatusClick('complete')"
				>
					<font-awesome-icon fixed-width :icon="['fas', 'check']" />
				</div>
				<div
					v-else-if="isCallDismissed"
					class="dismissed-call"
					role="button"
					data-tooltip="Dismissed"
					data-position="right"
					@click.stop="onChipStatusClick('dismissed')"
				>
					<font-awesome-icon fixed-width :icon="['far', 'circle']" />
				</div>

				<div
					v-else
					class="new-call"
					role="button"
					data-tooltip="New"
					data-position="right"
					@click.stop="onChipStatusClick('incomplete')"
				>
					<font-awesome-icon fixed-width :icon="['fas', 'circle']" />
				</div>

				{{ call.callback_number | formatPhoneNumber }}
			</router-link>

			<h5
				v-if="call.patient_name || call.patient_dob"
				class="inbox-item-subtitle"
			>
				<div class="inbox-dob-container">
					<span v-if="call.patient_dob" class="call-dob">
						{{ call.patient_dob }}
					</span>

					<span
						v-if="call.patient_name && call.patient_dob"
						class="call-spacer"
					>
						&bull;
					</span>
				</div>
				<span v-if="call.patient_name" class="call-phone">
					{{ call.callback_number | formatPhoneNumber }}
				</span>
			</h5>
		</div>
		<div class="inbox-item-timestamp">
			{{ call.created_at | toTimestamp }}
		</div>
		<div class="inbox-item-section py-2 w-100 chip-set flex-row z-10">
			<chip
				v-if="call.is_urgent"
				condensed
				class="bg-orange-100 text-orange-500 border-orange-200 hover:bg-orange-200 hover:text-orange-600"
				@click.stop="onUrgentClick"
			>
				Urgent
			</chip>
			<chip v-else condensed @click.stop="onNonUrgentClick">
				Non-Urgent
			</chip>
			<chip v-if="hasFallback" condensed>
				Fallback
			</chip>
			<chip
				v-for="type in call.types"
				:key="type.id"
				condensed
				@click.stop="onChipTypeClick(type)"
			>
				{{ type.name }}
			</chip>

			<chip
				v-if="hasNotes"
				condensed
				@click.stop="onChipStatusClick('noted')"
			>
				Notes
			</chip>
		</div>
	</div>
</template>

<script>
import Chip from './Chip'
import { mapActions } from 'vuex'

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		Chip,
	},

	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Determine if the call is the current active call being displayed.
		 *
		 * @return {Boolean}
		 */
		active() {
			return this.call.sid === this.$route.params.sid
		},

		/**
		 * Determine if the current call has fallback notifications.
		 *
		 * @return {Boolean}
		 */
		hasFallback() {
			if (!this.call || !Array.isArray(this.call.events)) {
				return false
			}

			return this.call.events.some(event => {
				return (
					event.type === 'fallback-notification' ||
					event.summary === 'Fallback notification sent'
				)
			})
		},

		/**
		 * Determine if the call has any attached notes.
		 *
		 * @return {Boolean}
		 */
		hasNotes() {
			return this.call.notes && this.call.notes.length > 0
		},

		/**
		 * Determine if the audio only chip should be displayed.
		 *
		 * @return {Boolean}
		 */
		isAudioOnly() {
			return !this.call.patient_name && !this.call.patient_dob
		},

		/**
		 * Determine if the current call is complete.
		 *
		 * @return {Boolean}
		 */
		isComplete() {
			return !!this.call.completed_at
		},

		/**
		 * Determine if the current call is dismissed.
		 *
		 * @return {Boolean}
		 */
		isCallDismissed() {
			return !!this.call.dismissed_by
		},
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Handle a filter chip being clicked.
		 *
		 * @param {String} status
		 * @return {void}
		 */
		onChipStatusClick(status) {
			const { statuses } = this.$store.state.calls.filters

			if (!statuses.includes(status)) {
				this.applyFilters({ statuses: [...statuses, status] })

				this.fetchCalls()
			}
		},

		/**
		 * Handle the call type chip click event.
		 *
		 * @param {Object} type
		 * @return {void}
		 */
		onChipTypeClick({ id, name }) {
			const { types } = this.$store.state.calls.filters

			const exists = types.findIndex(type => type.id === id) > -1

			if (!exists) {
				this.applyFilters({ types: [{ id, name }] })

				this.fetchCalls()
			}
		},

		/**
		 * Handle the non-urgent chip click event.
		 *
		 * @return {void}
		 */
		onNonUrgentClick() {
			const { nonUrgent } = this.$store.state.calls.filters

			if (!nonUrgent) {
				this.setNonUrgentFilter(true)

				this.fetchCalls()
			}
		},

		/**
		 * Handle the urgent click event.
		 *
		 * @return {void}
		 */
		onUrgentClick() {
			const { urgent } = this.$store.state.calls.filters

			if (!urgent) {
				this.setUrgentFilter(true)

				this.fetchCalls()
			}
		},

		...mapActions('calls', {
			fetchCalls: 'get',
			applyFilters: 'setFilters',
			setUrgentFilter: 'setUrgentFilter',
			setNonUrgentFilter: 'setNonUrgentFilter',
		}),
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'InboxCard',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * The call to be displayed.
		 *
		 * @type {Object}
		 */
		call: {
			type: Object,
			required: true,
		},
	},
}
</script>
